<template>
  <div class="container">
    <div class="total">
      <ek-total :isConutTo="true" />
    </div>
    <ek-legend />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
}
.total {
  margin-top: 10px;
}
</style>
