<template>
  <ek-panel title="投诉建议">
    <div class="box">
      <div class="text">
        <div class="t">本年投诉事件</div>
        <div class="v-box">
          <count-to
            separator=""
            :startVal="0"
            :endVal="total || 0"
            :duration="2000"
            class="v"
          /><span class="unit">件</span>
        </div>
      </div>
      <div class="chart-box">
        <div class="item">
          <ek-chart
            :options="lOptions"
            :animation="true"
            :animationData="lOptions.series[0].data"
          />
        </div>
        <div class="item">
          <ek-chart
            :options="rOptions"
            :animation="true"
            :animationData="rOptions.series[0].data"
          />
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      lOptions: {
        color: [
          '#1DADFF',
          '#00C800',
          '#24DCF7',
          '#275FC6',
          '#12B796',
          '#D95040',
          '#FFC000',
          '#FF7800',
          '#43FFD9'
        ],
        title: {
          text: `投诉分类`,
          top: '45%',
          left: '49%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${params.value}件</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        series: [
          {
            name: '投诉分类',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['30%', '55%'],
            label: {
              color: '#fff',
              formatter: params => {
                var strs = params.name.split('') //字符串数组
                var str = ''
                for (var i = 0, s; (s = strs[i++]); ) {
                  //遍历字符串数组
                  str += s
                  if (!(i % 8)) str += '\n' //按需要求余
                }
                return `${str} {d|${params.value}}`
              },
              fontSize: 15,
              rich: {
                d: {
                  color: '#2AFFEB',
                  fontSize: 15
                }
              }
            },
            itemStyle: {
              borderColor: '#050A0F',
              borderWidth: 3
            },
            selectedMode: 'single',
            data: []
          }
        ]
      },
      rOptions: {
        color: [
          '#1DADFF',
          '#00C800',
          '#24DCF7',
          '#275FC6',
          '#12B796',
          '#D95040',
          '#FFC000',
          '#FF7800',
          '#43FFD9'
        ],
        title: {
          text: `投诉来源`,
          top: '45%',
          left: '49%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 16,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = `<div class="tooltip-box column">
                      <span class="b-t">${params.seriesName}</span>
                      <div class="text" style="font-size:18px;margin-top:5px;">
                      <div class="right">
                          <span>${params.marker}</span>
                          <span class="t">${params.name}</span>
                          <span class="v">${params.value}件</span>
                        </div>
                      </div>
                    </div>`
            return el
          }
        },
        series: [
          {
            name: '投诉来源',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['30%', '55%'],
            label: {
              color: '#fff',
              formatter: params => {
                var strs = params.name.split('') //字符串数组
                var str = ''
                for (var i = 0, s; (s = strs[i++]); ) {
                  //遍历字符串数组
                  str += s
                  if (!(i % 8)) str += '\n' //按需要求余
                }
                return `${str} {d|${params.value}}`
              },
              fontSize: 15,
              rich: {
                d: {
                  color: '#2AFFEB',
                  fontSize: 15
                }
              }
            },
            itemStyle: {
              borderColor: '#050A0F',
              borderWidth: 3
            },
            selectedMode: 'single',
            data: []
          }
        ]
      }
    }
  },
  components: {},
  mounted() {
    this.fetchData('tousustat', '10')
    this.fetchData('tousustat', '20')
    this.fetchData('tousustat', '30')
  },
  methods: {
    async fetchData(code, subCode) {
      const { resultCode, resultData } = await this.$axios.post(
        '/cmsv2/content/page',
        {
          needDraft: false,
          status: '1',
          typeCode: code,
          otherParam: {
            sub_type_code: subCode
          }
        }
      )
      if (resultCode === '200' && resultData) {
        this.formatItemForData(subCode, resultData)
      }
    },
    formatItemForData(code, data) {
      if (code == '10') {
        console.log(code, data)
        let seriesData = []
        data.content.map(item => {
          seriesData.push({
            name: item.title,
            value: item.value
          })
        })
        this.lOptions.series[0].data = seriesData
      } else if (code == '20') {
        console.log(code, data)
        let seriesData = []
        data.content.map(item => {
          seriesData.push({
            name: item.title,
            value: item.value
          })
        })
        this.rOptions.series[0].data = seriesData
      } else if (code == '30') {
        console.log(code, data)
        this.total = data.content[0].value
      }
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: mFont;
  src: url('../../../styles/font/LED.ttf');
}
.box {
  height: 321px - 24px;
  padding: 10px 30px 14px 30px;
  @include flex(column);
  .text {
    @include flex();
    align-items: center;
    background: rgba($color: #ef9c00, $alpha: 0.2);
    padding: 0 16px;
    width: calc(100% - 32px);
    height: 68px;
    .t {
      // width: 93px;
      font-size: 18px;
      margin-right: 16px;
    }
    .v-box {
      @include flex();
      align-items: baseline;
      color: #ef9c00;
      .v {
        font: 40px mFont;
        line-height: 44px;
        margin-bottom: 10px;
      }
      .unit {
        font-size: 24px;
      }
    }
  }
  .chart-box {
    flex: 1;
    @include flex();
    .item {
      flex: 1;
    }
  }
}
</style>
