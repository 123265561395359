<template>
  <div class="cs-l1">
    <ek-panel title="交易数据">
      <div class="box">
        <div class="tools">
          <div class="item-box space-between">
            <ek-item-info title="交易额：" :value="`${formData.total}万元`" />
          </div>
          <div class="item-box flex-end">
            <chart-legend v-if="!empty" />
            <!-- <ek-select
              :list="dropDwons.holiday"
              :defaultVal="formData.holidayVal"
              width="100px"
              @click="handleHoliday"
            /> -->
            <div class="item">
              <ek-segements
                :index="formData.dateVal"
                :list="['月', '年']"
                :defaultSelect="formData.holidayVal ? false : true"
                @click="handleDate"
              />
            </div>
          </div>
        </div>
        <div class="chart-box">
          <ek-chart
            v-if="!empty"
            :options="options"
            :animation="true"
            :animationData="options.series[0].data"
          />
          <ek-empty v-else></ek-empty>
        </div>
      </div>
    </ek-panel>
  </div>
</template>

<script>
import chartLegend from './chartLegend'
import { cs_l3 } from '@/api/cs'

export default {
  data() {
    return {
      formData: {
        dateVal: 0,
        total: 0
      },
      empty: false,
      options: {
        title: {
          text: '单位(万元)',
          textStyle: {
            color: '#fff',
            fontSize: 18
          },
          left: '1.8%'
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let { dateVal } = this.formData
            let el = ''
            params.forEach(item => {
              let date = this.dayjs(item.name).format(
                dateVal == 0 ? 'YYYY/MM/DD' : 'YYYY/MM'
              )
              el = `<div class="tooltip-box column center">
                      <span class="time">${date}</span>
                      <div class="text marginTop">
                        <span class="t">${item.seriesName}：</span>
                        <span class="v">${parseFloat(
                          (item.value / 10000).toFixed(2)
                        )}万元</span>
                      </div>
                    </div>`
            })
            return el
          }
        },
        grid: {
          top: '16%',
          left: '8%',
          right: '3%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: val => {
              let { dateVal } = this.formData
              let unit = dateVal == 0 ? '日' : dateVal == 1 ? '月' : ''
              let date =
                dateVal == 0
                  ? this.dayjs(val).format('DD')
                  : dateVal == 1
                  ? this.dayjs(val).format('MM')
                  : val
              return date + unit
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC'
            }
          },
          // 不显示刻度线
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          }
          // data: this.xAxis
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#2973AC',
              type: [3, 5]
            }
          },
          axisLabel: {
            color: '#fff',
            fontSize: 16,
            formatter: function(val) {
              return parseFloat((val / 10000).toFixed(2))
            }
          }
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '交易额',
            type: 'line',
            smooth: true,
            symbol: 'none',
            lineStyle: {
              color: '#EF9C00'
            },
            markPoint: {
              symbol: 'circle',
              symbolSize: 10,
              label: {
                show: false
              },
              data: [
                {
                  type: 'max',
                  itemStyle: {
                    color: '#EF9C00'
                  }
                },
                {
                  type: 'min',
                  itemStyle: {
                    color: '#00C0FF'
                  }
                },
                {
                  type: 'average',
                  itemStyle: {
                    color: '#fff'
                  }
                }
              ]
            },
            markLine: {
              symbol: 'none',
              label: {
                show: false
              },
              lineStyle: {
                color: '#EF003E',
                type: [3, 5]
              },
              data: [{ type: 'average', name: 'Avg' }]
            },
            data: []
          }
        ]
      }
    }
  },
  components: {
    chartLegend
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let { dateVal } = this.formData
      let params = {
        dateType: this.dateType(dateVal + 1)
      }

      const { resultCode, resultData } = await cs_l3(params)
      if (resultCode === '200' && resultData) {
        let seriesData = []
        let xAxisData = []
        let total = 0
        resultData.map(item => {
          total += item.transactionAmount
          seriesData.push(item.transactionAmount || 0)
          xAxisData.push(item.date)
        })
        this.options.xAxis.data = xAxisData
        this.options.series[0].data = seriesData
        this.formData.total = parseFloat((total / 10000).toFixed(2))
        this.empty = !resultData.length
        console.log('cs_l3', resultData)
      }
    },
    handleDate(value) {
      this.formData.holidayVal = null
      this.formData.dateVal = value
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  height: 290px;
  @include flex(column);
  .tools {
    @include flex(column);
    .item-box {
      @include flex();
      padding: 0 20px 0 25px;
      align-items: center;
    }
    .item-box:first-child {
      margin-top: 15px;
    }
    .space-between {
      justify-content: space-between;
    }
    .flex-end {
      justify-content: flex-end;
    }
  }

  .chart-box {
    flex: 1;
    position: relative;
    @include flex();
    justify-content: center;
  }
}
.item {
  margin-left: 30px;
}
</style>
