<template>
  <div class="chart-legend">
    <div class="item-legend average">
      <span>平均</span>
    </div>
    <div class="item-legend min">
      <span>最低</span>
    </div>
    <div class="item-legend max">
      <span>最高</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.chart-legend {
  @include flex();
  align-items: center;
  margin-right: 48px;
  .item-legend {
    position: relative;
    @include flex();
    align-items: center;
  }
  .average {
    margin-right: 38px;
  }
  .average::after {
    content: '';
    position: absolute;
    left: -64px;
    width: 54px;
    border-bottom: 1.5px dashed #ef003e;
  }

  .min {
    margin-right: 38px;
  }
  .min::after {
    content: '';
    position: absolute;
    left: -17px;
    width: 12px;
    height: 12px;
    background: #00c0ff;
    border-radius: 50%;
  }
  .max::after {
    content: '';
    position: absolute;
    left: -17px;
    width: 12px;
    height: 12px;
    background: #ef9c00;
    border-radius: 50%;
  }
}
</style>
