<template>
  <ek-panel title="舆情分析 ">
    <div class="box">
      <div class="left">
        <div class="text">
          <span class="title">历史数据采集量</span>
          <ICountUp
            class="value"
            ref="cs-r2-iCountUp"
            :options="{
              separator: ','
            }"
            :endVal="formData.total || 0"
          />
        </div>
        <div class="item">
          <span class="l-chart-title">近一年互联网情绪占比</span>
          <ek-chart
            :options="lOptions"
            :animation="true"
            @ekCharts="chartFn"
            :animationData="lOptions.series[0].data"
          />
        </div>
      </div>
      <div class="right">
        <span class="t">景区资讯TOP排行</span>
        <div class="t-list">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="handleListItem(item)"
          >
            <span>TOP{{ index + 1 }}.</span>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { cs_r2_total, cs_r2_l, cs_r2_r } from '@/api/cs'

export default {
  data() {
    return {
      formData: {
        total: 0
      },
      lOptions: {
        color: ['#00FFEA', '#00FF9C', '#EF9C00'],
        title: {
          text: '',
          top: '44%',
          left: '69%',
          textAlign: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 22,
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          formatter: params => {
            let el = ''
            el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}件</span>
                      </div>
                    </div>`
            return el
          }
        },
        legend: {
          orient: 'vertical',
          top: '40%',
          left: '8%',
          icon: 'rect',
          itemWidth: 20,
          itemHeight: 20,
          textStyle: {
            color: '#fff',
            fontSize: 20,
            padding: [0, 10]
          }
        },
        series: [
          {
            type: 'pie',
            center: ['70%', '50%'],
            radius: ['60%', '90%'],
            label: {
              show: false
            },
            data: [
              { value: 0, name: '正面' },
              { value: 0, name: '中性' },
              { value: 0, name: '负面' }
            ]
          }
        ]
      },
      list: [],
      chart: null
    }
  },
  mounted() {
    this.apiFn(cs_r2_total, 'cs_r2_total')
    this.apiFn(cs_r2_l, 'cs_r2_l')
    this.apiFn(cs_r2_r, 'cs_r2_r')
    // this.$eventBus.$off('ek-chart-click')
    // this.$eventBus.$on('ek-chart-click', params => {
    //   this.handleChartItem(params)
    // })
    // this.chart.on('click', function(params) {
    //   console.log(params)
    //   // _this.$eventBus.$emit('ek-chart-click', params)
    // })
  },
  methods: {
    chartFn(chart) {
      let _this = this
      chart.on('click', function(params) {
        // console.log(params.name)
        _this.$eventBus.$emit('cs_emo_list', params.name)
      })
    },
    async apiFn(api, code) {
      let params = {
        needPage: true,
        page: 0,
        size: 5
      }
      const { resultCode, resultData } = await api(params)
      if (resultCode === '200' && resultData) {
        this.formatItemForData(code, resultData)
      }
    },

    formatItemForData(code, data) {
      console.log(code, data)
      if (code == 'cs_r2_total') {
        this.formData.total = data.totalNum
      } else if (code == 'cs_r2_l') {
        let total = data.goodNum + data.neutralNum + data.badNum
        let persent = (data.goodNum / total).toFixed(2) * 100
        let text = `${persent + '%' + this.lOptions.series[0].data[0].name}`
        this.lOptions.title.text = text
        this.lOptions.series[0].data[0].value = data.goodNum
        this.lOptions.series[0].data[1].value = data.neutralNum
        this.lOptions.series[0].data[2].value = data.badNum
      } else if (code == 'cs_r2_r') {
        this.list = data.content
      }
    },
    handleListItem(item) {
      this.$eventBus.$emit('cs_emo_detail', item.url)
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: mFont;
  src: url('../../../styles/font/LED.ttf');
}
.box {
  @include flex();
  height: 270px - 40px;
  padding: 20px 25px;
  .left {
    flex: 2;
    @include flex(column);
    .text {
      @include flex();
      align-items: center;
      .title {
        font-size: 20px;
        font-family: DS-Digital-Bold;
      }
      .value {
        color: #ef9c00;
        line-height: 44px !important;
        margin-left: 24px;
        font: 50px mFont;
        margin-bottom: 8px;
      }
    }
    .item {
      flex: 1;
      position: relative;
      .l-chart-title {
        position: absolute;
        left: 0;
        top: 30px;
        font-size: 20px;
      }
    }
  }
  .right {
    @include flex(column);
    justify-content: space-between;
    flex: 1.5;
    width: 40%;
    .t {
      color: #ef9c00;
      font-size: 20px;
      font-weight: bold;
    }
    .t-list {
      @include flex(column);
      .item {
        width: 100%;
        @include flex();
        align-items: center;
        height: 40px;
        background: rgba($color: #202738, $alpha: 0.5);
        cursor: pointer;
        span {
          // text-align: center;
          padding: 0 5px;
          font-size: 16px;
          white-space: nowrap;
        }
      }
    }
    // .t-list > .item > span:nth-child(1) {
    //   flex: 1;
    // }
    .t-list > .item > span:nth-child(2) {
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .t-list > .item:nth-child(odd) {
      background: rgba($color: #202738, $alpha: 0.8);
    }
  }
}
</style>
