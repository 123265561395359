import Stomp from 'stompjs'
import {
  RABBITMQ_USERNAME,
  RABBITMQ_PASSCODE,
  RABBITMQ_HOST,
  RABBITMQ_QUEUE
} from './mqtt'

export default {
  data() {
    return {}
  },
  methods: {
    // 连接RabbitMQ服务器
    connectRabbitMQ() {
      console.log('process==', RABBITMQ_HOST)
      this.client = Stomp.client('ws://' + RABBITMQ_HOST + '/ws')
      var headers = {
        login: RABBITMQ_USERNAME,
        passcode: RABBITMQ_PASSCODE
      }
      this.client.connect(
        headers,
        this.onRabbitMQConnected,
        this.ononRabbitMQConnectFailed
      )
    },
    // 成功连接的回调
    onRabbitMQConnected(frame) {
      const queueName = `temp-queue-${Math.random()
        .toString(36)
        .substring(7)}`

      console.log('success ===> ' + frame + '成功连接...')
      // this.client.subscribe(RABBITMQ_QUEUE, this.onRabbitMQresponseCallback, {
      //   'x-queue-name': queueName
      // })
      this.listenerRabbitConnect(queueName)
    },
    // 连接失败的回调
    ononRabbitMQConnectFailed(frame) {
      console.log('Failed ===> ' + frame + '重连...')
      this.connectRabbitMQ()
    },
    // 接收到服务端消息的回调函数
    onRabbitMQresponseCallback(message) {
      let receivedMsg = JSON.parse(message.body)
      this.receivedMsg = receivedMsg
      this.listenerRabbitMQ(receivedMsg)
    },
    onRabbitMQDisconnect() {
      this.client.disconnect(function() {
        console.log('disconnect ===> 断开连接...')
      })
    }
  }
}
