<template>
  <ek-panel title="事件预警">
    <div class="box">
      <div class="i-box">
        <div class="t-header">
          <span>序号</span>
          <span>紧急状态</span>
          <span>类型</span>
          <span>设备/来源</span>
          <span>报警内容</span>
          <span>报警位置</span>
          <span>报警时间</span>
        </div>
        <div class="t-list" v-if="!empty">
          <swiper ref="orderSwiper" :options="swiperOptions">
            <swiper-slide v-for="(items, index) in list" :key="index">
              <div
                class="item"
                v-for="(item, index) in items"
                :key="index"
                @click="handleItem(item)"
                @mouseenter="onEnterClick"
                @mouseleave="onLeaveClick"
              >
                <span>{{ item.number }}</span>
                <span class="status">
                  <div
                    class="status-box"
                    :class="
                      item.level
                        ? item.level == '紧急'
                          ? 'emergent'
                          : item.level == '重要'
                          ? 'important'
                          : 'same'
                        : ''
                    "
                  >
                    {{ item.level || '-' }}
                  </div>
                </span>
                <span>{{ item.type }}</span>
                <span>{{ item.dev }}</span>
                <el-tooltip
                  effect="dark"
                  :content="item.content"
                  placement="top-start"
                >
                  <span>{{ item.content }}</span>
                </el-tooltip>

                <span>{{ item.postion || '-' }}</span>
                <span>{{ item.time }}</span>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <ek-empty v-else></ek-empty>
      </div>
    </div>
  </ek-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { cs_r1 } from '@/api/cs'
import msgclient from '@/utils/rabbitMQ/msgclient.js'

export default {
  data() {
    return {
      empty: false,
      list: [],
      sourceList: [],
      swiperOptions: {
        direction: 'vertical',
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        },
        speed: 900,
        loop: false
      },
      dicType: 'alarm_type'
    }
  },
  mixins: [msgclient],
  computed: {
    ...mapGetters(['dictionaryMap']),
    swiper() {
      return this.$refs.orderSwiper.$swiper
    }
  },
  watch: {
    dictionaryMap: {
      handler(obj) {
        if (obj[this.dicType]) {
          this.fetchData()
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$store.dispatch('fresh', this.dicType)
    this.connectRabbitMQ()
  },
  methods: {
    listenerRabbitConnect(queueName) {
      this.client.subscribe(
        '/exchange/wdt.ioc.exchange',
        this.onRabbitMQresponseCallback,
        {
          'x-queue-name': queueName
        }
      )
    },
    /**
     * RabbitMQ  消息监听事件
     * @param msgData
     */
    listenerRabbitMQ(msgData) {
      console.log(msgData)
      if (!msgData.data) return
      if (
        msgData.messageType == 'alarm' &&
        msgData.data.alarmType == 'DEVICE_ALARM'
      ) {
        this.sourceList.pop()
        this.sourceList.splice(0, 0, msgData.data)
        let tempList = this.sourceList.map((obj, i) => {
          obj.number = i + 1
          obj.level = this.level_status(obj.urgencyDegree)
          obj.type = this.dictionary(obj.alarmType, this.dicType)
          obj.dev = obj.sourceType
          obj.content = obj.alarmContent
          obj.postion = obj.alarmLocation
          obj.time = obj.alarmTime
            ? this.dayjs(obj.alarmTime).format('MM/DD')
            : '-'
          return obj
        })

        this.list = this.groupList(tempList, 3)
      }
    },
    async fetchData() {
      const { resultCode, resultData } = await cs_r1()
      if (resultCode === '200' && resultData) {
        console.log('cs_r1', resultData)
        let tempList = resultData.map((obj, i) => {
          obj.number = i + 1
          obj.level = this.level_status(obj.urgencyDegree)
          obj.type = this.dictionary(obj.alarmType, this.dicType)
          obj.dev = obj.sourceType
          obj.content = obj.alarmContent
          obj.postion = obj.alarmLocation
          obj.time = obj.alarmTime
            ? this.dayjs(obj.alarmTime).format('MM/DD')
            : '-'
          return obj
        })

        this.sourceList = tempList
        this.list = this.groupList(tempList, 3)
        this.empty = !resultData.length
      }
    },

    handleItem(item) {
      console.log(item)
    },
    // onHover(item) {
    //   console.log(item.content)
    // },
    onEnterClick() {
      this.swiper.autoplay.stop()
    },
    onLeaveClick() {
      this.swiper.autoplay.start()
    },

    level_status(level) {
      switch (level) {
        case '10':
          return '紧急'
        case '20':
          return '重要'
        case '30':
          return '一般'
      }
    }
  },
  beforeDestroy() {
    if (!this.client.connected) return
    this.onRabbitMQDisconnect()
  }
}
</script>

<style scoped lang="scss">
.box {
  @include flex();
  height: 290px - 10px;
  padding: 5px 30px;
}

.i-box {
  @include flex(column);
  flex: 1;
  .t-header {
    @include flex();
    align-items: center;
    height: 60px;
    background: #202738;
    span {
      font-size: 20px;
      text-align: center;
    }
  }

  .t-list {
    @include flex(column);
    .swiper-container {
      height: 220px;
      width: 100%;
    }
    .item {
      width: 100%;
      @include flex();
      align-items: center;
      height: 70px;
      background: rgba($color: #202738, $alpha: 0.8);
      // cursor: pointer;
      span {
        text-align: center;
      }
    }
    .status {
      @include flex();
      align-items: center;
      justify-content: center;
    }
    .status-box {
      padding: 5px 13px;
      border-radius: 6px;
    }
    .emergent {
      color: #ff0101;
      background: rgba($color: #e11919, $alpha: 0.3);
      border: 1px solid #e11919;
    }
    .important {
      color: #ff8a01;
      background: rgba($color: #ff8a01, $alpha: 0.3);
      border: 1px solid #ff8a01;
    }
    .same {
      color: #00ff9c;
      background: rgba($color: #00ff9c, $alpha: 0.3);
      border: 1px solid #00ff9c;
    }
  }

  .t-header > span:nth-child(1),
  .swiper-slide > .item > span:nth-child(1) {
    flex: 2;
  }
  .t-header > span:nth-child(2),
  .swiper-slide > .item > span:nth-child(2) {
    flex: 1.5;
  }
  .t-header > span:nth-child(3),
  .swiper-slide > .item > span:nth-child(3) {
    flex: 1.5;
  }
  .t-header > span:nth-child(4),
  .swiper-slide > .item > span:nth-child(4) {
    flex: 3;
  }
  .t-header > span:nth-child(5),
  .swiper-slide > .item > span:nth-child(5) {
    flex: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .t-header > span:nth-child(6),
  .swiper-slide > .item > span:nth-child(6) {
    flex: 2;
  }
  .t-header > span:nth-child(7),
  .swiper-slide > .item > span:nth-child(7) {
    flex: 1.5;
  }

  .swiper-slide > .item:nth-child(odd) {
    background: rgba($color: #202738, $alpha: 0.5);
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  width: 150px !important;
}
</style>
